import { render, staticRenderFns } from "./LocationForm.vue?vue&type=template&id=aeb45af8&"
import script from "./LocationForm.vue?vue&type=script&lang=js&"
export * from "./LocationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/Hounslow-Connect/admin-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aeb45af8')) {
      api.createRecord('aeb45af8', component.options)
    } else {
      api.reload('aeb45af8', component.options)
    }
    module.hot.accept("./LocationForm.vue?vue&type=template&id=aeb45af8&", function () {
      api.rerender('aeb45af8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/locations/forms/LocationForm.vue"
export default component.exports